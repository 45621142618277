<template>
    <div>
      <div class="air__utils__heading">
        <h5>
          <span class="mr-3">Managemen On-Boarding Caption</span>
        </h5>
      </div>
      <div class="row">
        <div class="col-md-12">
          <a-form :form="form" @submit="handleSubmit">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="row">
                      <a-form-item label="Teks Notifikasi Halaman Beranda" class="col-md-6">
                        <a-textarea name="short_caption" 
													placeholder="Silakan isi teks notifikasi" 
													:rows="4"
													v-decorator="[
														'short_caption', {
															rules: [{
																required: true,
																message: 'Field tidak boleh kosong'
															}]
														}
													]"
												/>
                      </a-form-item>
                    </div>
										<div class="row">
                      <a-form-item label="Teks Notifikasi Halaman On-Boarding" class="col-md-6">
                        <a-textarea name="long_caption" 
													placeholder="Silakan isi teks notifikasi" 
													:rows="4" 
													v-decorator="[
														'long_caption', {
															rules: [{
																required: true,
																message: 'Field tidak boleh kosong'
															}]
														}
													]"
												/>
                      </a-form-item>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <button type="submit" class="btn btn-primary btn-with-addon">
                      <span class="btn-addon">
                        <i class="btn-addon-icon fe fe-send"/>
                      </span>
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </a-form>
        </div>
      </div>
    </div>
  </template>
<script>  
  import { getCaption, postCaption } from '@/api/onboarding.api';
  
  export default {
    data() {
      return {
        form: this.$form.createForm(this)
      }
    },
    mounted() {
      this.fetchData();
    },
    methods: {
      beforeUpload() {
        return false;
      },
      fetchData() {
        getCaption().then((response) => {
          const { data } = response;
          if(data) {
						this.form.setFieldsValue({ short_caption: data.short_caption });
						this.form.setFieldsValue({ long_caption: data.long_caption });
          }
        })
      },
      handleSubmit(e) {
        e.preventDefault();
        this.form.validateFields((err, value) => {
          if (!err) {
            this.$nprogress.start();
            let formData = new FormData();
						formData.set("short_caption", value.short_caption);
						formData.set("long_caption", value.long_caption);
            this.submitData(formData);
          }
        })
      },
      async submitData(params) {
        try {
          let response = await postCaption(params);
          if (response) {
            this.$notification["success"]({
              message: "Sukses",
              description: "Berhasil memperbaharui data"
            });
          }
        } catch (error) {
          /**/
        }
        this.$nprogress.done();
      },
    }
  }
  </script>
  