var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_vm._m(0),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('a-form',{attrs:{"form":_vm.form},on:{"submit":_vm.handleSubmit}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"row"},[_c('a-form-item',{staticClass:"col-md-6",attrs:{"label":"Teks Notifikasi Halaman Beranda"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
														'short_caption', {
															rules: [{
																required: true,
																message: 'Field tidak boleh kosong'
															}]
														}
													]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t'short_caption', {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\trules: [{\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Field tidak boleh kosong'\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}]\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"name":"short_caption","placeholder":"Silakan isi teks notifikasi","rows":4}})],1)],1),_c('div',{staticClass:"row"},[_c('a-form-item',{staticClass:"col-md-6",attrs:{"label":"Teks Notifikasi Halaman On-Boarding"}},[_c('a-textarea',{directives:[{name:"decorator",rawName:"v-decorator",value:([
														'long_caption', {
															rules: [{
																required: true,
																message: 'Field tidak boleh kosong'
															}]
														}
													]),expression:"[\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t'long_caption', {\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\trules: [{\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\trequired: true,\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\tmessage: 'Field tidak boleh kosong'\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t}]\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t}\n\t\t\t\t\t\t\t\t\t\t\t\t\t]"}],attrs:{"name":"long_caption","placeholder":"Silakan isi teks notifikasi","rows":4}})],1)],1)]),_c('div',{staticClass:"col-md-12"},[_c('button',{staticClass:"btn btn-primary btn-with-addon",attrs:{"type":"submit"}},[_c('span',{staticClass:"btn-addon"},[_c('i',{staticClass:"btn-addon-icon fe fe-send"})]),_vm._v(" Simpan ")])])])])])])],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"air__utils__heading"},[_c('h5',[_c('span',{staticClass:"mr-3"},[_vm._v("Managemen On-Boarding Caption")])])])}]

export { render, staticRenderFns }